import React, { useMemo } from "react";

import { InView } from "utils/animation";
import ProgramCard from "./ProgramCard";

import { sortCollectionItems } from "utils/sort-collection-items";

//  TODO: fix duplicate support-funding
import "./Program.scss";

const Program = ({ title, description, categories, allProjects }) => {
  const sortItems = (items) => useMemo(() => sortCollectionItems(allProjects.nodes, items), [allProjects, items]);

  return (
    <div className="program">
      <InView className="program__content-wrapper">
        <div className="program__content a-fade-up">
          <h2 className="program__title t-lg-plus t-bold">{title}</h2>
          <p className="program__description t-sm">{description}</p>
        </div>
      </InView>

      <div className="program__list-wrapper">
        {categories.map(({ category, items }) => (
          <div className="program__list__item program-category" key={category}>
            <h3 className="program-category__title t-lg t-dark-gray">{category}</h3>
            <div className="program-cards">
              {sortItems(items).map((project, index) => (
                <div key={index} className="program-cards__card">
                  <ProgramCard className="a-fade-up-from-bottom u-shadow" project={project} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Program;

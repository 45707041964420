// import React, { useMemo } from "react";
import React from "react";
import { useTranslation } from "@w3f/gatsby-theme-w3f/src/utils/i18n";
import SEO from "@w3f/gatsby-theme-w3f/src/components/site/SEO";
import { graphql } from "gatsby";
// import { sortCollectionItems } from "utils/sort-collection-items";
import Layout from "components/site/Layout";
import Hero from "components/Hero";
import Container from "layouts/Container";
import Program from "../components/Program";
import Cta from "components/Cta";

const FundingSupportPage = ({ data: { page, allProjects, footerImage } }) => {
  const { t } = useTranslation();
  const { funding, support, seo } = page.frontmatter;
  const meta = seo ? { ...seo } : {}; // In case the seo object is null

  return (
    <Layout footerImage={footerImage}>
      <SEO {...meta} />
      <Container className="container--t-margin-sm container--b-margin-md" noOverflow={false}>
        <Hero
          title={t("Funding & Support")}
          content={<p className="t3">Grow your business by leveraging a fully decentralized ecosystem.</p>}
        />
      </Container>

      <Container className="container--t-margin-sm" noOverflow={false}>
        <Program
          title="Funding"
          description="The Polkadot ecosystem offers multiple funding options for initiatives innovating in and growing the ecosystem."
          categories={funding}
          allProjects={allProjects}
        />
        <Program
          title="Support"
          description="The ecosystem offers supplemental support for business development, technical implementation, and other key objectives."
          categories={support}
          allProjects={allProjects}
        />
      </Container>

      <Container className="container--v-margin">
        <Cta
          title="Unsure where to go? We can help."
          link={{
            to: "https://forms.gle/tfBZdSb5Yor86zU98",
            title: "Drop us a note",
          }}
        />
      </Container>
    </Layout>
  );
};;

export default FundingSupportPage;

export const query = graphql`
  query ($language: String!) {
    ...Locales
    page: markdownRemark(
      fields: { langKey: { eq: $language } }
      fileAbsolutePath: { regex: "//pages/funding-support.md/" }
    ) {
      html
      frontmatter {
        funding {
          category
          items
        }
        support {
          category
          items
        }
        support {
          category
          items
        }
        seo {
          ...Seo
        }
      }
      fields {
        langKey
        slug
      }
    }
    allProjects: allMarkdownRemark(
      filter: { fields: { langKey: { eq: $language } }, fileAbsolutePath: { regex: "//(funding-support)//" } }
    ) {
      nodes {
        html
        frontmatter {
          id
          image {
            childImageSharp {
              gatsbyImageData(height: 265, layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
            }
          }
          title
          description
          link {
            title
            url
          }
        }
        fields {
          langKey
          slug
        }
      }
    }
    footerImage: file(name: { eq: "grants-large" }) {
      ...BgImage
    }
  }
`;
